import { ActionIcon, Avatar, Badge, Button, Flex, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconFileText, IconPhoto } from "@tabler/icons-react";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AccountApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import SettingsForm from "../../components/settings/settings-form";
import { ColumnType, Table } from "../../components/table";
import { IBankList } from "../../interfaces/IBankLIst";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";

const AccountList: React.FC = () => {
  const breadcrumbs = useBreadcrumb();
  const tableRef = React.useRef<any>(null);
  const [action, setAction] = useState<any>([]);
  const [filters] = React.useState({ query: "" });
  const { bankNames } = useSelector((state: { general: IGeneral }) => state.general);
  const PAY_ACC = usePermission("PAY_ACC");

  const reloadTable = () => {
    tableRef.current.reload();
  };

  const currenciesTag = (val: string) => {
    switch (val) {
      case "TUGRUG": {
        return <Text>{"Төгрөг"}</Text>;
      }
      case "DOLLAR": {
        return <Text>{"Доллар"}</Text>;
      }
      default: {
        return <Text>{val || "-"}</Text>;
      }
    }
  };

  const onCloseAll = () => {
    setAction([]);
  };

  const columns = useHeader({
    currenciesTag,
    bankNames,
    isView: PAY_ACC.isView,
    isEdit: PAY_ACC.isEdit,
    onClick: (key, record) => {
      setAction([key, record]);
    },
  });

  return (
    <PageLayout
      title="Дансны жагсаалт"
      subTitle="В2В харилцаанд ашиглах дансны жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Button key={0} onClick={() => setAction(["new"])} hidden={!PAY_ACC.isCreate}>
          Данс нэмэх
        </Button>,
      ]}>
      <Paper>
        {PAY_ACC.isView ? (
          <Table ref={tableRef} name="account.list" columns={columns} filters={filters} loadData={(data) => AccountApi.list(data!)} />
        ) : (
          PAY_ACC.accessDenied()
        )}
        <Modal withCloseButton={false} opened={action[0] === "new"} onClose={() => setAction([])} size="xl" centered padding={0}>
          <Paper px="lg">
            <SettingsForm reload={reloadTable} action={action} onClose={() => onCloseAll()} />
          </Paper>
        </Modal>
        <Modal withCloseButton={false} opened={action[0] === "edit" || action[0] === "details"} onClose={() => setAction([])} size="xl" centered padding={0}>
          <Paper px="lg">
            <SettingsForm reload={reloadTable} action={action} onClose={() => onCloseAll()} />
          </Paper>
        </Modal>
      </Paper>
    </PageLayout>
  );
};

export { AccountList };

const useHeader = ({
  onClick,
  currenciesTag,
  bankNames,
  isView,
  isEdit,
}: {
  onClick: (key: string, record: IBankList) => void;
  currenciesTag: (key: string) => JSX.Element;
  bankNames: IReference[];
  isView: boolean;
  isEdit: boolean;
}): ColumnType<IBankList>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Flex gap="sm">
          {isView && (
            <Tooltip position="bottom" label="Дэлгэрэнгүй">
              <ActionIcon color="" variant="light" onClick={() => onClick("details", record)}>
                <IconFileText />
              </ActionIcon>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip position="bottom" label="Засах">
              <ActionIcon color="" variant="light" onClick={() => onClick("edit", record)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
    width: "120px",
  },
  {
    title: "Банкны нэр",
    render: (record) => (
      <Flex gap={10} align="center">
        <Avatar src={`${record?.icon}`} radius="xs">
          <IconPhoto />
        </Avatar>
        <Text w={"max-content"}>{bankNames.find((item: IReference) => item.code === record?.bankName)?.name ?? "-"}</Text>
      </Flex>
    ),
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "name",
    render: (record) => <Text size="sm">{record?.number}</Text>,
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "category",
    render: (record) => <Text size="sm">{record?.name}</Text>,
  },
  {
    title: "Дансны товч нэр",
    sorter: true,
    dataIndex: "category",
    render: (record) => <Text size="sm">{record?.shortName}</Text>,
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return <Text size="sm">{currenciesTag(record?.currency)}</Text>;
    },
  },
  {
    title: "Үндсэн эсэх",
    sorter: true,
    align: "center",
    dataIndex: "description",
    render: (record) => {
      return record?.isDefault === true ? (
        <Badge key={0} color="green" size="md" radius="sm" variant="outline">
          Тийм
        </Badge>
      ) : (
        <Badge key={1} color="gray" size="md" radius="sm" variant="outline">
          Үгүй
        </Badge>
      );
    },
  },
  {
    title: "Банк баталгаажилт",
    sorter: true,
    align: "center",
    dataIndex: "description",
    render: (record) => {
      return record?.isVerified === true ? (
        <Badge key={3} color="green" size="md" radius="sm" variant="outline">
          Тийм
        </Badge>
      ) : (
        <Badge key={4} color="gray" size="md" radius="sm" variant="outline">
          Үгүй
        </Badge>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return record?.isActive === true ? (
        <Badge
          key={5}
          color="green"
          size="md"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          Идэвхтэй
        </Badge>
      ) : (
        <Badge
          key={6}
          color="gray"
          size="md"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "unit",
    render: (record) => <Text size="sm">{record?.business.regNumber}</Text>,
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Text c="indigo" size="sm">
            #{record?.business.partner.refCode}
          </Text>
          <Text size="sm">{record?.business.partner.businessName}</Text>
        </Text>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Text c="indigo" size="sm">
            #{record?.business?.refCode ?? "-"}
          </Text>
          <Text size="sm">{record?.business?.profileName ?? "-"}</Text>
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return record?.regUser ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.regUser?.avatar ?? ""} />
          <Text size="sm" w="max-content">
            {record?.regUser?.lastName ? record.regUser.lastName[0] + "." : ""} {record?.regUser?.firstName ?? "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "description",
    render: (record) => <Text size="sm">{dayjs(record?.createdAt).format("YYYY-MM-DD") || "-"}</Text>,
  },
];

const useBreadcrumb = () => [
  {
    to: "#",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Төлбөр тооцоо",
  },
  {
    label: "В2В данс жагсаалт",
  },
];
